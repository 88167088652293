import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Header from "../components/Header"
import FancyTitle from '../components/FancyTitle'
import { StyledArticle } from "../components/components.css"
export default ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <Header>
        <FancyTitle title={post.frontmatter.title} />
      </Header>
      <StyledArticle>
        <div className="blog-content" dangerouslySetInnerHTML={{ __html: post.html }} />
      </StyledArticle>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`